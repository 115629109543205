import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "quotation" }
const _hoisted_2 = {
  key: 0,
  class: "text"
}
const _hoisted_3 = {
  key: 1,
  class: "author"
}
const _hoisted_4 = {
  key: 2,
  class: "relationship"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("blockquote", _hoisted_1, [
    ($props.quotation?.quote)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.quotation?.quote), 1))
      : _createCommentVNode("", true),
    ($props.quotation?.author)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.quotation?.author), 1))
      : _createCommentVNode("", true),
    ($props.quotation?.relationship)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.quotation?.relationship), 1))
      : _createCommentVNode("", true)
  ]))
}